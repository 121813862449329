
<template>
  <div>
    <b-modal
      id="edit-user-sidebar"
      ref="modal"
      header-class="headerModal"
      centered
      hide-footer
      :title="`${$t('Edit')} ${formUser.editUserRequestDto.firstName} ${formUser.editUserRequestDto.lastName}`"
      size="lg"
      no-close-on-backdrop
      @close="onCancel"
    >
      <!-- <template #title>
        <strong style="color: #101828;">{{ `${$t('Edit')} ${formUser.editUserRequestDto.firstName} ${formUser.editUserRequestDto.lastName}` }}</strong>
      </template> -->
      <!-- <template
        v-slot:header-close
      >
        <button
          style="color: #98A2B3;padding-left: 0px; padding-right: 0px "
          class="fa fa-close"
          @click="onCancel"
        />
      </template> -->
      <b-form>
        <b-tabs
          v-model="tabIndex"
        >
          <pre />
          <pre />
          <b-tab
            :title="$t('UserInformation')"
          >
            <template #title>
              <strong style="font-weight: 500; color: black">{{ $t("UserInformation") }}</strong>
            </template>
            <div
              class="d-flex"
            >
              <!-- <b-form-group
                  id="input-group-6"
                  class="mb-0"
                />
                <pre /> -->
              <b-form-group
                id="input-group-1"
                :label="`${$t('Salutation')}:`"
                label-for="input-1"
              >
                <vue-select
                  v-model="formUser.editUserRequestDto.salutation"
                  required
                  :options="salutation"
                  :placeholder="$t('SelectSalutation')"
                />
              </b-form-group>
              <b-form-group
                id="input-group-1"
                :label="`${$t('FirstName')}:`"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="$v.formUser.editUserRequestDto.firstName.$model"
                  :state="validateState('firstName')"
                  aria-describedby="input-1-live-feedback"
                />
                <b-form-invalid-feedback
                  id="input-1-live-feedback"
                >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="d-flex">
              <b-form-group
                id="input-group-1"
                :label="`${$t('LastName')}:`"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="$v.formUser.editUserRequestDto.lastName.$model"
                  :state="validateState('lastName')"
                  aria-describedby="input-1-live-feedback"
                />
                <b-form-invalid-feedback
                  id="input-1-live-feedback"
                >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                id="input-group-2"
                :label="`${$t('Gender')}:`"
                label-for="input-2"
              >
                <vue-select
                  v-model="$v.formUser.editUserRequestDto.gender.$model"
                  required
                  :options="genderOptions"
                  :reduce="(e) => e.value"
                  label="text"
                  :placeholder="$t('SelectGender')"
                  :state="validateState('gender')"
                  aria-describedby="input-1-live-feedback"
                />
              </b-form-group>
            </div>
            <div class="d-flex">
              <b-form-group
                id="input-group-9"
                :label="`${$t('DateofBirth')}:`"
                label-for="input-9"
              >
                <date-picker
                  v-model="$v.formUser.editUserRequestDto.dateOfBirth.$model"
                  value-type="format"
                  class="form-control"
                  format="DD-MM-YYYY"
                  type="date"
                  :state="validateState('dateOfBirth')"
                  :placeholder="$t('DateofBirth')"
                  style="width: 100%;"
                  :lang="lang"
                />
                <b-form-invalid-feedback :state="validateState('dateOfBirth') || submitDate == false">
                  {{ $t('Please_Select_birthday') }}
                </b-form-invalid-feedback>
              </b-form-group>


              <b-form-group
                id="input-group-9"
                :label="`${$t('PhoneNumber')}:`"
                label-for="input-9"
              >
                <!-- <b-form-input
                    id="input-9"
                    v-model="$v.formUser.editUserRequestDto.phoneNumber.$model"
                    type="number"
                    step="any"
                    :state="validateState('phoneNumber')"
                    aria-describedby="input-1-live-feedback"
                  />
                  <b-form-invalid-feedback
                    id="input-1-live-feedback"
                  >{{ $t('This_is_a_required_field_and_must_contains_numbers') }}</b-form-invalid-feedback> -->
                <vue-phone-number-input
                  v-model="formUser.editUserRequestDto.phoneNumber"
                  :default-country-code="formUser.editUserRequestDto.countryCode"
                  :error="
                    isPhoneNumberValid == null ? false : isPhoneNumberValid == false ? true : false
                  "
                  :preferred-countries="['AL', 'CH', 'DE']"
                  :no-example="true"
                  @update="getFormattedNumber"
                />
              </b-form-group>
            </div>
            <div class="d-flex">
              <b-form-group
                id="input-group-9"
                :label="`${$t('Email')}:`"
                label-for="input-9"
              >
                <b-form-input
                  id="input-9"
                  v-model="$v.formUser.editUserRequestDto.email.$model"
                  :state="validateState('email')"
                  aria-describedby="input-1-live-feedback"
                />
                <b-form-invalid-feedback
                  id="input-1-live-feedback"
                >{{ $t('Must_be_email_format') }}</b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                id="input-group-9"
                :label="`${$t('Role')}:`"
                label-for="input-9"
              >
                <vue-select
                  v-model="$v.formUser.editUserRequestDto.role.$model"
                  :options="filteredOptions"
                  :reduce="(e) => e.value"
                  label="text"
                  :placeholder="$t('SelectRole')"
                  :state="validateState('role')"
                  aria-describedby="input-1-live-feedback"
                />
              </b-form-group>
            </div>
            <div class="d-flex">
              <b-form-group
                id="input-group-9"
                :label="`${$t('Salary')}: Bruto`"
                label-for="input-9"
              >
                <b-form-input
                  id="input-9"
                  v-model="$v.formUser.editUserRequestDto.salary.$model"
                  :state="validateState('salary')"
                  aria-describedby="input-1-live-feedback"
                />
                <b-form-invalid-feedback
                  id="input-1-live-feedback"
                >{{ $t('Must_be_email_format') }}</b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                id="input-group-9"
                :label="`${$t('Salary')}: Neto`"
                label-for="input-9"
              >
                <b-form-input
                  id="input-5"
                  v-model.number="brutoValue"
                  disabled
                  :title="`${formUser.editUserRequestDto.salary}-5% te kontributit te puntorit = ${formUser.editUserRequestDto.salary - perqindja},  Tatimi nga 80€-250€ - 4%,250€-450€ 8%, 450€+ 10%, Tatimi = `+tatimi"
                  aria-describedby="input-1-live-feedback"
                />
                <b-form-invalid-feedback
                  id="input-1-live-feedback"
                >{{ $t('This_is_a_required_field_and_must_be_email_format') }}</b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div>
              <b-form-group style="display: flex; align-items: end;">
                <b-form-checkbox
                  id="checkbox-8"
                  v-model="resetPassword"
                  name="checkbox-8"
                  :value="true"
                  :unchecked-value="false"
                >
                  {{ $t('ResetPassword') }}
                </b-form-checkbox>
              </b-form-group>
            </div>
          </b-tab>
          <b-tab
            :title="$t('AddressInformation')"
          >
            <template #title>
              <strong style="font-weight: 500; color: black">{{ $t("Address") }}</strong>
            </template>
            <div style="display: flex;">
              <b-form-group
                id="input-group-3"
                :label="`${$t('Street')}:`"
                label-for="input-3"
              >
                <b-form-input
                  id="input-3"
                  v-model="$v.formUser.editUserRequestDto.street.$model"
                  :state="validateState('street')"
                  aria-describedby="input-1-live-feedback"
                />
                <b-form-invalid-feedback
                  id="input-1-live-feedback"
                >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                id="input-group-6"
                style="width: 50%;"
                :label="`${$t('Door/HouseNumber')}:`"
                label-for="input-6"
              >
                <b-form-input
                  id="input-6"
                  v-model="$v.formUser.editUserRequestDto.doorNumber.$model"
                  :state="validateState('doorNumber')"
                  aria-describedby="input-1-live-feedback"
                />
                <b-form-invalid-feedback
                  id="input-1-live-feedback"
                >{{ $t('This_is_a_required_field_and_must_contains_numbers') }}</b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div style="display: flex;">
              <b-form-group
                id="input-group-6"
                :label="`${$t('PostalCode')}:`"
                label-for="input-6"
                style="width: 50%;"
              >
                <b-form-input
                  id="input-6"
                  v-model="$v.formUser.editUserRequestDto.postalCode.$model"
                  :state="validateState('postalCode')"
                  aria-describedby="input-1-live-feedback"
                />
                <b-form-invalid-feedback
                  id="input-1-live-feedback"
                >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                id="input-group-5"
                :label="`${$t('City')}:`"
                label-for="input-5"
              >
                <b-form-input
                  id="input-5"
                  v-model="$v.formUser.editUserRequestDto.city.$model"
                  :state="validateState('city')"
                  aria-describedby="input-1-live-feedback"
                />
                <b-form-invalid-feedback
                  id="input-1-live-feedback"
                >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
              </b-form-group>
            </div>
            <b-form-group
              id="input-group-7"
              :label="`${$t('Country')}:`"
              label-for="input-7"
            >
              <b-form-input
                id="input-7"
                v-model="$v.formUser.editUserRequestDto.country.$model"
                :state="validateState('country')"
                aria-describedby="input-1-live-feedback"
              />
              <b-form-invalid-feedback
                id="input-1-live-feedback"
              >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
            </b-form-group>
          </b-tab>
          <b-tab
            v-if="client != 'NotaBrillant'"
          >
            <template #title>
              <strong style="font-weight: 500; color: black">{{ $t("EmployeeDocuments") }}</strong>
            </template>
            <div>
              <b-form-group
                id="input-group-6"
                style="font-weight: bold"
                class="mb-0"
              />
              <div
                v-if="imagePassport.length > 0"
              >
                <div class="files">
                  <span style="color: #262E6C; font-weight: bold;">{{ $t("Passport") }}</span>
                  <div
                    style="display: flex; flex-direction: row !important;"
                  >
                    <div
                      v-for="(img, index) in imagePassport"
                      :key="img.photoId"
                      :class="{'second-photo': index === 1, 'third-photo': index === 2,}"
                      style="background-color: transparent; border-radius: 8px; height: 56px; display: flex; justify-content: center; align-items: center;gap: 20px;"
                    >
                      <template v-if="img.src.includes('.png') || img.src.includes('.jpeg') || img.src.includes('.webp')">
                        <img
                          :src="img.src"
                          :class="{'photo': true, 'hovered': hoveredIndex === img.photoId, 'second-photo': index === 1, 'third-photo': index === 2}"
                          :style="{ transform: `scale(${img.zoom})` }"
                          alt="Not an image"
                          @click="openLightbox(index)"
                        >
                        <b-icon-x-circle
                          :key="img.fileDataId"
                          style="margin-left: 15px; margin-right: 5px; cursor: pointer;"
                          @click="handleDeleteClick(img.photoId)"
                        />
                      </template>
                      <template v-else>
                        <img
                          v-b-tooltip="$t('DocumentOrPhoto')"
                          style="width:40px;height:40px"
                          src="../../../assets/images/documentLogo.svg"
                        >
                        <b-icon-x-circle
                          :key="img.fileDataId"
                          style="margin-left: 15px; margin-right: 5px; cursor: pointer;"
                          @click="handleDeleteClick(img.photoId)"
                        />
                      </template>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-else
                class="files"
              >
                <span style="color: #262E6C; font-weight: bold;">{{ $t("No Passport") }}</span>
              </div>
              <div
                v-if="imageResidencePermit.length > 0"
              >
                <div class="files">

                  <span style="color: #262E6C; font-weight: bold;">{{ $t("Contract") }}</span>

                  <div
                    v-for="(img, index) in imageResidencePermit"
                    :key="img.photoId"
                    :class="{'second-photo': index === 1, 'third-photo': index === 2,}"
                    style="background-color: transparent; border-radius: 8px; height: 56px; display: flex; justify-content: center; align-items: center;gap: 20px;"
                  >
                    <template v-if="img.src.includes('.png') || img.src.includes('.jpeg') || img.src.includes('.webp')">
                      <img
                        :src="img.src"
                        :class="{'photo': true, 'hovered': hoveredIndex === img.photoId, 'second-photo': index === 1, 'third-photo': index === 2}"
                        :style="{ transform: `scale(${img.zoom})` }"
                        alt="Not an image"
                        @click="openLightbox2(index)"
                      >
                      <b-icon-x-circle
                        :key="img.fileDataId"
                        style="margin-left: 15px; margin-right: 5px; cursor: pointer;"
                        @click="handleDeleteClick(img.photoId)"
                      />
                    </template>
                    <template v-else>
                      <img
                        v-b-tooltip="$t('DocumentOrPhoto')"
                        style="width:40px;height:40px"
                        src="../../../assets/images/documentLogo.svg"
                      >
                      <b-icon-x-circle
                        :key="img.fileDataId"
                        style="margin-left: 15px; margin-right: 5px; cursor: pointer;"
                        @click="handleDeleteClick(img.photoId)"
                      />
                    </template>
                  </div>
                </div>
              </div>
              <div
                v-else
                class="files"
              >
                <span style="color: #262E6C; font-weight: bold;">{{ $t("No Contract") }}</span>
              </div>
              <div v-if="imageSocialNumber.length > 0">
                <div
                  style="display: flex; flex-direction: row !important;"
                >
                  <div class="files">
                    <span style="color: #262E6C; font-weight: bold;">{{ $t("CV") }}</span>

                    <div
                      v-for="(img, index) in imageSocialNumber"
                      :key="img.photoId"
                      :class="{'second-photo': index === 1, 'third-photo': index === 2,}"
                      style="background-color: transparent; border-radius: 8px; height: 56px; display: flex; justify-content: center; align-items: center;gap: 20px;"
                    >
                      <template v-if="img.src.includes('.png') || img.src.includes('.jpeg') || img.src.includes('.webp')">
                        <img
                          :src="img.src"
                          :class="{'photo': true, 'hovered': hoveredIndex === img.photoId, 'second-photo': index === 1, 'third-photo': index === 2}"
                          :style="{ transform: `scale(${img.zoom})` }"
                          alt="Not an image"
                          @click="openLightbox1(index)"
                        >
                        <b-icon-x-circle
                          :key="img.fileDataId"
                          style="margin-left: 15px; margin-right: 5px; cursor: pointer;"
                          @click="handleDeleteClick(img.photoId)"
                        />
                      </template>
                      <template v-else>
                        <img
                          v-b-tooltip="$t('DocumentOrPhoto')"
                          style="width:40px;height:40px"
                          src="../../../assets/images/documentLogo.svg"
                        >
                        <b-icon-x-circle
                          :key="img.fileDataId"
                          style="margin-left: 15px; margin-right: 5px; cursor: pointer;"
                          @click="handleDeleteClick(img.photoId)"
                        />
                      </template>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-else
                class="files"
              >
                <span style="color: #262E6C; font-weight: bold;">{{ $t("No CV") }}</span>
              </div>
            </div>
            <!-- <vue-easy-lightbox
              :visible="lightboxVisible"
              :index="lightboxIndex"
              :imgs="imagePassport"
              @hide="closeLightbox"
            />
            <vue-easy-lightbox
              :visible="lightboxVisible1"
              :index="lightboxIndex1"
              :imgs="imageSocialNumber"
              @hide="closeLightbox1"
            />
            <vue-easy-lightbox
              :visible="lightboxVisible2"
              :index="lightboxIndex2"
              :imgs="imageResidencePermit"
              @hide="closeLightbox2"
            /> -->
            <div style="display: flex">
              <AddFile
                v-if="imagePassport == 0"
                ref="addFile1"
                :folder="'UserDocuments'"
                :label-text="$t('UploadSketch')"
                :file-data-type="'Passport'"
                :file-name="files"
              />
              <AddFile
                v-if="imageResidencePermit == 0"
                ref="addFile2"
                :folder="'UserDocuments'"
                :label-text="$t('UploadContract')"
                :file-data-type="'ResidencePermit'"
                :file-name="files"
              />
              <AddFile
                v-if="imageSocialNumber == 0"
                ref="addFile3"
                :folder="'UserDocuments'"
                :label-text="$t('UploadCV')"
                :file-data-type="'SocialNumber'"
                :file-name="files"
              />
            </div>
          </b-tab>

          <b-tab>
            <template #title>
              <strong style="font-weight: 500; color: black">{{ $t("EmergencyContact") }}</strong>
            </template>
            <div class="d-flex">
              <b-form-group
                id="input-group-3"
                :label="`${$t('FirstName')}:`"
                label-for="input-11"
              >
                <b-form-input
                  id="input-1"
                  v-model="formUser.editUserRequestDto.emergencyContactDto.firstName"
                  aria-describedby="input-1-live-feedback"
                />
                <b-form-invalid-feedback
                  id="input-11-live-feedback"
                >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                id="input-group-1"
                :label="`${$t('LastName')}:`"
                label-for="input-12"
              >
                <b-form-input
                  id="input-1"
                  v-model="formUser.editUserRequestDto.emergencyContactDto.lastName"
                  aria-describedby="input-1-live-feedback"
                />
                <b-form-invalid-feedback
                  id="input-1-live-feedback"
                >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="d-flex">
              <b-form-group
                id="input-group-3"
                :label="`${$t('Relation')}:`"
                label-for="input-13"
              >
                <b-form-input
                  id="input-2"
                  v-model="formUser.editUserRequestDto.emergencyContactDto.relationship"
                  aria-describedby="input-1-live-feedback"
                />
                <b-form-invalid-feedback
                  id="input-1-live-feedback"
                >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                id="input-group-3"
                :label="`${$t('PhoneNumber')}:`"
                label-for="input-14"
              >
                <vue-phone-number-input
                  v-model="formUser.editUserRequestDto.emergencyContactDto.phoneNumber"
                  :default-country-code="formUser.editUserRequestDto.emergencyContactDto.countryCode"
                  :error="
                    isPhoneNumberValidEmergency == null ? false : isPhoneNumberValidEmergency == false ? true : false
                  "
                  :preferred-countries="['AL', 'CH', 'DE']"
                  :no-example="true"
                  @update="getFormattedNumberEmergency"
                />
              </b-form-group>
            </div>
          </b-tab>
        </b-tabs>
      </b-form>

      <div class="buttonsEverywhereToggle">
        <b-button
          type="button"
          class="buttonSubmit"
          :disabled="isPhoneNumberValid == false || isPhoneNumberValid == null || loading"
          @click="onSubmit"
        >
          <div
            v-if="loading"
            class="spinner-border spinner-border-sm"
          />
          {{ $t('Submit') }}
        </b-button>
        <b-button
          type="button"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px; ; background: white; color: black; border-color: white;font-weight: 400;"

          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </b-button>

      </div>
    </b-modal>
  </div>
</template>

<script>
// import flatPickr from 'vue-flatpickr-component'
import DatePicker from 'vue2-datepicker';
import moment from 'moment';
// import VueEasyLightbox from 'vue-easy-lightbox';
import 'vue2-datepicker/index.css';
import { mapGetters, mapActions } from 'vuex';
import { validationMixin } from 'vuelidate';
import {
  required, minLength, numeric, alpha, alphaNum, email, minValue,
} from 'vuelidate/lib/validators';
import AWS from 'aws-sdk';
import { AWSurl, client } from '../../../domainConfig';
import AddFile from '../../../modals/AddFile.vue'


export default {

  components: {
    DatePicker,
    // VueEasyLightbox,
    AddFile,
  },
  mixins: [validationMixin],
  props: {
    // eslint-disable-next-line vue/require-default-prop
    editUser: {
      type: Object,
    },
  },
  data() {
    return {
      hoveredIndex: null,
      client: client.clientName,
      visible: false,
      lightboxVisible: false,
      lightboxIndex: 0,
      lightboxVisible1: false,
      lightboxIndex1: 0,
      lightboxVisible2: false,
      lightboxIndex2: 0,
      perqindja: 0,
      tatimi: 0,
      submitDate: false,
      bucketName: AWSurl.bucketName,
      showFileError: false,
      showFileError2: false,
      showFileError3: false,
      isPhoneNumberValid: null,
      isPhoneNumberValidEmergency: null,
      loading: false,
      tabIndex: 0,
      loading2: false,
      loading3: false,
      s3: null,
      files: [],
      formUser: {
        editUserRequestDto: {
          userId: '',
          firstName: '',
          lastName: '',
          salutation: '',
          gender: '',
          personRole: '',
          countryCode: '',
          phoneNumber: '',
          email: '',
          role: '',
          salary: '',
          street: '',
          doorNumber: '',
          postalCode: '',
          socialNumber: '',
          city: '',
          country: '',
          dateOfBirth: '',
          maritalStatus: '',
          residencePermit: '',
          nationality: '',
          employeeType: '',
          houseNumber: '',
          emergencyContactDto: {
            firstName: '',
            lastName: '',
            countryCode: '',
            relationship: '',
            phoneNumber: '',
          },
        },
        editDocumentRequestDto: [],
      },
      resetPassword: false,
      imagePassport: [],
      imageSocialNumber: [],
      imageResidencePermit: [],
      selectedDate: null,
      config: {
        altInput: true,
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
      },
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
      },
      roleOptions: ['CEO', 'Sewer', 'TeamLeader', 'Admin', 'Manager', 'PatternMaker', 'Trainee', 'HandSewer'],
      salutation: ['Mr.', 'Ms.', 'BA.', 'BSc.', 'DI.', 'MA.', 'MSc.', 'MBA.', 'PhD.'],
      genderOptions: [
        { value: 'Male', text: this.$t('Male') },
        { value: 'Female', text: this.$t('Female') },
      ],

    }
  },
  validations: {
    formUser: {
      editUserRequestDto: {
        firstName: {
          required,
          // minLength: minLength(3),
          // alpha,
        },
        lastName: {
          required,
          // minLength: minLength(3),
          // alpha,
        },
        salutation: {
          required,
          // minLength: minLength(3),
          // alpha,
        },
        gender: {
          required,
        },
        city: {
          required,
          minLength: minLength(3),
          alpha,
        },
        dateOfBirth: {
          required,
          // dateFormat,
        },
        phoneNumber: {
          required,
          minLength: minLength(8),
          // numeric,
        },
        salary: {},
        postalCode: {
          required,
          minLength: minLength(3),
          alphaNum,
        },
        doorNumber: {
          required,
          minLength: minValue(1),
          numeric,
        },
        street: {
          required,
          minLength: minLength(3),
        },
        country: {
          required,
          minLength: minLength(3),
        },
        email: {
          required,
          email,
        },
        role: {
          required,
        },
      },
    },
  },
  computed: {
    ...mapGetters(['getUserRole', 'getFullUsersById']),
    filteredOptions() {
      const options = this.getUserRole.map((role) => {
        return {
          value: role,
          text: this.$t(role),
        }
      });
      return options;
    },
    userById() {
      return this.$store.getters.getFullUsersById;
    },
    brutoValue() {
      this.calculateTatimi();

      return this.formUser.editUserRequestDto.salary - this.perqindja - this.tatimi;
    },
  },
  watch: {
    // visible(value) {
    //   if (value == false) {
    //     this.imagePassport = []
    //     this.imageResidencePermit = []
    //     this.imageSocialNumber = []
    //   }
    // },
    userById: {
      handler(newVal) {
        console.log('hellooo', newVal)
        this.formUser.editUserRequestDto = {
          userId: newVal.userResponseDto.userId,
          firstName: newVal.userResponseDto.firstName,
          lastName: newVal.userResponseDto.lastName,
          salutation: newVal.userResponseDto.salutation,
          gender: newVal.userResponseDto.gender,
          personRole: newVal.userResponseDto.personRole,
          countryCode: newVal.userResponseDto.countryCode,
          phoneNumber: newVal.userResponseDto.phoneNumber,
          email: newVal.userResponseDto.email,
          role: newVal.userResponseDto.role,
          salary: newVal.userResponseDto.salary,
          street: newVal.userResponseDto.street,
          doorNumber: newVal.userResponseDto.doorNumber,
          postalCode: newVal.userResponseDto.postalCode,
          city: newVal.userResponseDto.city,
          country: newVal.userResponseDto.country,
          socialNumber: newVal.userResponseDto.socialNumber,
          maritalStatus: newVal.userResponseDto.maritalStatus,
          residencePermit: newVal.userResponseDto.residencePermit,
          nationality: newVal.userResponseDto.nationality,
          employeeType: newVal.userResponseDto.employeeType,
          dateOfBirth: moment(newVal.userResponseDto.dateOfBirth).format('DD-MM-YYYY'),
          houseNumber: newVal.userResponseDto.houseNumber,
          isActive: newVal.userResponseDto.isActive,
          totalDays: newVal.userResponseDto.totalDays,
          deactivatedTime: newVal.userResponseDto.deactivatedTime,
          remainingDays: newVal.userResponseDto.remainingDays,
          emergencyContactDto: {
            firstName: newVal.userResponseDto.emergencyContactDto ? newVal.userResponseDto.emergencyContactDto.firstName : null,
            lastName: newVal.userResponseDto.emergencyContactDto ? newVal.userResponseDto.emergencyContactDto.lastName : null,
            relationship: newVal.userResponseDto.emergencyContactDto ? newVal.userResponseDto.emergencyContactDto.relationship : null,
            phoneNumber: newVal.userResponseDto.emergencyContactDto ? newVal.userResponseDto.emergencyContactDto.phoneNumber : null,
            contryCode: newVal.userResponseDto.emergencyContactDto ? newVal.userResponseDto.emergencyContactDto.contryCode : null,
          },
          // created: moment(newVal.userResponseDto.created).format('DD-MM-YYYY'),
        }

        // create an object to store arrays based on documentType
        for (let i = 0; i < newVal.documentFullResponseDto.length; i++) {
          const document = newVal.documentFullResponseDto[i];
          const documentType = document.documentType;
          for (let j = 0; j < document.documentPhotosResponseDtos.length; j++) {
            const photo = document.documentPhotosResponseDtos[j];
            const image = {
              src: photo.url,
              title: photo.fileName,
              documentId: document.documentId,
              photoId: photo.photoId,
              documentType: document.documentType,
              expiryDate: document.expiryDate,
            };
            let imageArray;
            if (documentType === 'Passport') {
              imageArray = this.imagePassport;
            } else if (documentType === 'ResidencePermit') {
              imageArray = this.imageResidencePermit;
            } else if (documentType === 'SocialNumber') {
              imageArray = this.imageSocialNumber;
            }
            const index = imageArray.findIndex(
              (existingImage) => {
                return existingImage.photoId === photo.photoId
                 && existingImage.documentId === document.documentId
              },
            );
            if (index === -1) {
              imageArray.push(image);
            } else {
              // Replace the existing image object with the new one
              imageArray.splice(index, 1, image);
            }
          }
        }
        // access the arrays based on documentType
      },
    },
  },
  mounted() {
    this.$parent.$on('reset-imagesEdit', this.resetImagesEdit);
  },
  beforeDestroy() {
    this.$parent.$off('reset-imagesEdit', this.resetImagesEdit);
  },
  created() {
    AWS.config.update({
      accessKeyId: AWSurl.accessKeyId,
      secretAccessKey: AWSurl.secretAccessKey,
      region: AWSurl.region,
    });

    this.s3 = new AWS.S3();
  },
  methods: {
    ...mapActions(['getUsersRole', 'deletePhoto']),
    validateState(name) {
      const { $dirty, $error } = this.$v.formUser.editUserRequestDto[name];
      return $dirty ? !$error : null;
    },
    resetImagesEdit() {
      this.imagePassport = [];
      this.imageSocialNumber = [];
      this.imageResidencePermit = [];
    },
    downloadFile(fileUrl) {
      // Extract filename from the URL
      const fileName = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);

      // Create a temporary anchor element
      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = fileName;

      // Trigger a click on the anchor element to start the download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    openLightbox(index) {
      this.lightboxIndex = index;
      this.lightboxVisible = true;
    },
    closeLightbox() {
      this.lightboxVisible = false;
    },
    openLightbox1(index) {
      this.lightboxIndex1 = index;
      this.lightboxVisible1 = true;
    },
    closeLightbox1() {
      this.lightboxVisible1 = false;
    },
    openLightbox2(index) {
      this.lightboxIndex2 = index;
      this.lightboxVisible2 = true;
    },
    closeLightbox2() {
      this.lightboxVisible2 = false;
    },
    handleHide() {
      this.visible = false
    },
    show() {
      this.visible = true
    },
    calculateTatimi() {
      this.perqindja = this.formUser.editUserRequestDto.salary * 0.05;

      if (this.formUser.editUserRequestDto.salary <= 84) {
        this.tatimi = 0;
      } else if (this.formUser.editUserRequestDto.salary > 84 && this.formUser.editUserRequestDto.salary <= 262.5) {
        const konstanta1 = this.formUser.editUserRequestDto.salary - this.perqindja - 80;
        this.tatimi = konstanta1 * 0.04;
      } else if (this.formUser.editUserRequestDto.salary > 262.5 && this.formUser.editUserRequestDto.salary <= 472.5) {
        const konstanta1 = this.formUser.editUserRequestDto.salary - this.perqindja - 250;
        const tatimi1 = 170 * 0.04;
        const tatimi2 = konstanta1 * 0.08;
        this.tatimi = tatimi1 + tatimi2;
      } else if (this.formUser.editUserRequestDto.salary > 472.5) {
        const tatimi1 = 170 * 0.04;
        const tatimi2 = 200 * 0.08;
        const konstanta1 = this.formUser.editUserRequestDto.salary - this.perqindja - 450;
        const tatimi3 = konstanta1 * 0.1;
        this.tatimi = tatimi1 + tatimi2 + tatimi3;
      }
    },
    handleDeleteClick(photoId) {
      console.log('photoId', photoId)
      this.$bvModal.msgBoxConfirm('Are you sure you want to delete this photo?', {
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Delete',
        cancelTitle: 'Cancel',
        cancelVariant: 'default',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then((value) => {
          if (value) {
            this.deletePhotoo(photoId);
          }
        })
        .catch((err) => {
          console.log('Error in msgBoxConfirm:', err);
        });
    },
    async deletePhotoo(value) {
      await this.deletePhoto({
        Id: value,
        successCallback: () => {
          this.imagePassport = [];
          this.imageSocialNumber = [];
          this.imageResidencePermit = [];
          this.$emit('getPhotos', this.formUser.editUserRequestDto.userId)
        },
      })
      console.log('photo', value)
    },
    zoomIn(index) {
      this.hoveredIndex = index;
    },
    zoomOut() {
      this.hoveredIndex = null;
    },
    showText() {
      this.isTextShown = true;
    },
    async onSubmit() {
      try {
        this.loading = true;
        if (this.imagePassport == 0 && this.client != 'NotaBrillant') {
          await this.$refs.addFile1.uploadFile();
        }
        if (this.imageResidencePermit == 0 && this.client != 'NotaBrillant') {
          await this.$refs.addFile2.uploadFile();
        }
        if (this.imageSocialNumber == 0 && this.client != 'NotaBrillant') {
          await this.$refs.addFile3.uploadFile();
        }
        this.$v.formUser.$touch();
        this.submitDate = true

        if (this.$v.formUser.$anyError) {
          return;
        }
        const formatedDate = moment(this.formUser.editUserRequestDto.dateOfBirth, 'DD-MM-YYYY').format('YYYY-MM-DD');
        const payload = {
          ...this.formUser.editUserRequestDto,
          dateOfBirth: formatedDate,
          editDocumentRequestDto: this.files,
          resetPassword: this.resetPassword,
        }
        this.$emit('onCancelToggle');
        this.$emit('edit', payload)
        console.log('paylodi', payload)
        this.$refs.modal.hide()
        this.onReset()
        setTimeout(() => { this.$v.$reset() }, 0)
        this.$nextTick(() => { this.$v.$reset() })
      } catch (error) {
        console.error('An error occurred during file upload:', error);
      } finally {
        this.loading = false; // Disable the button
        this.fileName = [];
      }
    },
    getFormattedNumber(n) {
      if (n.isValid == true) {
        this.isPhoneNumberValid = true;
        this.formUser.editUserRequestDto.phoneNumber = n.nationalNumber;
        this.formUser.editUserRequestDto.countryCode = n.countryCode;
      } else {
        this.formUser.editUserRequestDto.countryCode = '';
        this.isPhoneNumberValid = false;
        return;
      }
      console.log();
    },
    getFormattedNumberEmergency(n) {
      if (n.isValid == true) {
        this.isPhoneNumberValidEmergency = true;
        this.formUser.editUserRequestDto.emergencyContactDto.phoneNumber = n.nationalNumber;
        this.formUser.editUserRequestDto.emergencyContactDto.countryCode = n.countryCode;
      } else {
        this.formUser.editUserRequestDto.emergencyContactDto.countryCode = '';
        this.isPhoneNumberValidEmergency = false;
        return;
      }
      console.log();
    },
    onCancel() {
      this.$emit('onCancelToggle');
      this.$refs.modal.hide()
      this.imagePassport = []
      this.imageResidencePermit = []
      this.imageSocialNumber = []
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
      this.onReset()
    },
    onReset() {
      // Reset our form values
      this.files = []
      this.formUser.editUserRequestDto.firstName = ''
      this.formUser.editUserRequestDto.lastName = ''
      this.formUser.editUserRequestDto.salutation = ''
      this.formUser.editUserRequestDto.gender = ''
      this.formUser.editUserRequestDto.city = ''
      this.formUser.editUserRequestDto.street = ''
      this.formUser.editUserRequestDto.dateOfBirth = ''
      this.formUser.editUserRequestDto.postalCode = ''
      this.formUser.editUserRequestDto.doorNumber = ''
      this.formUser.editUserRequestDto.country = ''
      this.formUser.editUserRequestDto.countryCode = ''
      this.formUser.editUserRequestDto.phoneNumber = ''
      this.formUser.editUserRequestDto.email = ''
      this.formUser.editUserRequestDto.role = ''
      this.imagePassport = [];
      this.imageSocialNumber = [];
      this.imageResidencePermit = [];
      this.fileDataPassport = [];
      this.fileDataAufenthaltsbewilligung = [];
      this.formUser.editDocumentRequestDto = [];
      this.fileDataAHV = [];
      this.selectedDate = '';
      this.formUser.editUserRequestDto.emergencyContactDto.firstName = ''
      this.formUser.editUserRequestDto.emergencyContactDto.lastName = ''
      this.formUser.editUserRequestDto.emergencyContactDto.phoneNumber = ''
      this.formUser.editUserRequestDto.emergencyContactDto.relationship = ''
      this.formUser.editUserRequestDto.emergencyContactDto.countryCode = ''
      // Trick to reset/clear native browser form validation state
    },
  },
}
</script>

  <style lang="scss" scoped>
  .files {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    background-color: rgb(207, 207, 207);
    border: none;
    box-shadow: none;
    padding: 12px;
    color: #212529;
    border-radius: 8px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .buttonsEverywhereToggle {
    padding: 0px
  }
  .d-flex{
    width: 100%;
  }
  .photo{
    margin-left: 5px;
    height: 50px;
    border-radius: 8px;
  }
  .second-photo{
    margin-left: 5px;
  }
  .hovered {
    /* Additional styles for the hovered photo */
    transform: scale(7.2);
    border-radius: 0px;
    z-index: 10;
  }
  .third-photo{
    margin-left: 5px;
  }
    form {
    }
    .flex-form {
      display: flex;
      flex-direction: column;
    }

    .d-flex{
      width: 100%;
    }

    #input-group-1, #input-group-9, #input-group-2{
      width: 50%;
      margin-right: 8px;
    }

    #input-group-3, #input-group-5, #input-group-7{
      width: 50%;
    }

    #input-5{
      width: 100%;
    }

    #input-group-6, #input-group-3{
      margin-right: 8px;
    }

  </style>
